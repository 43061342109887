<template>
    <div class="cs-cookie-banner cs-block cs-no-padding" v-if="show">
        <div class="cs-block-base">
            <div class="container">
                <CSCookieBanner :data="data" />
            </div>
        </div>
    </div>
</template>
    
<script>
    import {computed} from '../libs/common-fn';

    export default {
        props: {
            data: {
                type: Object,
                default: () => {},
            },
        },
        components: {
            CSCookieBanner: () => import("../components/CSCookieBanner.vue")
        },
        data() {
            return {
                show:true
            };
        },
        mounted(){
            if(typeof window != 'undefined'){
                this.show = !this.cookies().display();
            }
        },
        computed: computed('CookieBanner')
    };
</script>

<style scoped lang="scss">
    @import "../styles/main.scss";

    .cs-cookie-banner {
        padding: 30px 0 !important;
        position: fixed !important;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #00274f;
        z-index: 101;
    
        *, ::v-deep * {
            color: white !important;
        }
    }
</style>
